main {
  animation: fadeIn .5s ease-in;
  background-color: var(--background__color);
  &.site__contact {
    background-color: var(--background_light__color);
    display: grid;
    min-height: calc(100vh - 80px);
    @include desktop {
      align-items: center;
      grid-template-columns: 60% 40%;
      justify-items: center;
    }
    .contact__form {
      padding: 4rem 2rem;
      h2 {
        color: var(--secondary__color);
      }
      p {
        color: var(--secondary__color);
        margin: 1rem 0;
      }
      form {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        .text_error {
          color: var(--text_error__color);
        }
        div {
          display: flex;
          flex-direction: column;
          gap: 1rem;
          @include desktop {
            flex-direction: row;
          }
          label {
            flex-basis: 50%;
          }
        }
        label {
          background-color: var(--background__color);
          border-radius: .75rem;
          color: var(--secondary__color);
          display: flex;
          flex-direction: column;
          padding: .75rem 1rem;
          input {
            background-color: var(--background__color);
            border: none;
            color: var(--text__color);;
            font-size: 1rem;
            line-height: 2rem;
            &:focus {
              outline: none;
            }
          }
          textarea {
            background-color: var(--background__color);
            border: none;
            color: var(--text__color);
            font-size: 1rem;
            resize: none;
            &:focus {
              outline: none;
            }
          }
        }
        button {
          align-self: flex-start;
          background-color: var(--secondary__color);
          border: none;
          color: var(--text_light__color);
          font-size: 1rem;
          font-weight: bold;
          padding: 1rem;
          width: 50%;
          &:hover {
            background-color: var(--primary__color);
          }
        }
      }
    }
    .contact__links {
      align-items: center;
      display: flex;
      height: 100%;
      justify-content: center;
      width: 100%;
      position: relative;
      overflow: hidden;
      .contact__video {
        position: absolute;
        object-fit: cover;
        z-index: 0;
      }
      ul {
        display: flex;
        flex-direction: column;
        gap: 3rem;
        padding: 4rem 0;
        li.contact__link {
          display: flex;
          gap: 1rem;
          z-index: 1;
          .link__badge {
            align-items: center;
            background-color: var(--primary__color);
            border-radius: 50%;
            color: var(--text_light__color);
            display: flex;
            font-size: 1.5rem;
            height: 3rem;
            justify-content: center;
            width: 3rem;
          }
          .link__content {
            a {
              color: var(--text_light__color);
            }
            small {
              color: var(--accent__color);
              display: block;
            }
          }
        }
      }
    }
  }
  &.fiber__container {
    max-width: 100vw;
    padding: 1rem;
    @include desktop {
      padding: 1.5rem 8rem;
    }
  }
  &.complements__container {
    max-width: 100vw;
    padding: 1.5rem;
    @include desktop {
      padding: 1.5rem 8rem;
    }
  }
  & .sidebar__container {
    display: flex;
    flex-direction: column-reverse;
    gap: 1rem;
    @include tablet {
      flex-direction: row;
      section {
        flex-basis: 80%;
      }
      aside {
        flex-basis: 20%;
      }
    }
  }
  &.concrete__container {
    max-width: 100vw;
    padding: 1rem;
    @include desktop {
      padding: 1.5rem 8rem;
    }
    section {
        text-align: center;
        h2 {
            color: var(--secondary__color);
            font-size: 2rem;
            padding: 1rem;
            @include desktop {
                font-size: 3rem;
            }
        }
        p {
            color: var(--text__color);
            font-size: 1.125rem;
            padding: 1rem 0 2rem 0;
        }
    }
    & .contact__links {
      background-image: none;
      & .contact__link {
        & .link__content {
          & a {
            h4 {
              color: var(--dark__color);
            }
          }
          & small {
            color: var(--text__color);
          }
        }
      }
    }
  }
}
