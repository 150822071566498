$colors: (
  'accent': '#c8ccf0',
  'primary': '#192bc2',
  'secondary': '#00218f',
  'dark': '#0c0047',
  'text': '#5a5a75',
  'text_light': '#f5f7fa',
  'text_subtitle': '#888e9a',
  'text_error': '#ff4d4f',
  'background': '#f4f5f9',
  'background_light': '#ffffff',
  'whatsapp': '#25D366',
  'whatsapp_dark': '#075e54',
  'alpha_blue': 'rgba(0, 0, 100, 0.5)',
);

:root {
  @each $color, $value in $colors {
    --#{$color}__color: #{$value};
  }
}

@mixin tablet {
  @media only screen and (min-width: 768px) {
    @content;
  }
}

@mixin desktop {
  @media only screen and (min-width: 980px) {
    @content;
  }
}

@mixin larger {
  @media only screen and (min-width: 1366px) {
    @content;
  }
}