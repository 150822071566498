header {
  background-color: var(--secondary__color);
  box-shadow: 0 1rem 3rem var(--dark__color);
  z-index: 10;
  .header__navbar {
    align-items: center;
    justify-content: space-between;
    display: flex;
    flex-direction: column;
    position: relative;
    @include desktop {
      flex-direction: row;
    }
    .navbar__brand {
      align-items: center;
      color: var(--text_light__color);
      display: flex;
      height: 96px;
      .brand__logo {
        width: 160px;
      }
    }
    .navbar__nav {
      height: 0px;
      order: 2;
      overflow: hidden;
      transition: all .5s ease;
      &.visible {
        height: fit-content; /* 15rem; */
        transition: all .5s ease;
      }
      @include desktop {
        height: auto;
        order: 1;
      }
      ul {
        align-items: center;
        display: flex;
        flex-direction: column;
        margin: 1.5rem 0 1rem 0;
        @include desktop {
          flex-direction: row;
        }
        li {
          list-style: none;
          @include desktop {
            margin: 0 .5rem 0 .5rem;
          }
          @include larger {
            margin: 0 2rem 0 2rem;
          }
          hr {
            background-color: var(--background_light__color);
            border-radius: 2px 2px 2px 2px;
            border-style: none;
            height: 4px;
            margin: .25rem 0;
            transition: width .5s;
            width: 0px;
          }
          a {
            color: var(--accent__color);
            font-size: 1.125rem;
            font-weight: bold;
            line-height: 1.75rem;
            text-decoration: none;
            &.active {
              color: var(--text_light__color);
              &+hr {
                width: 100%;
              }
            }
          }
        }
      }
    }
    .navbar__nav-mobile {
      order: 1;
      position: absolute;
      right: 1rem;
      top: 1rem;
      @include desktop {
        order: 2;
        position: relative;
        width: 160px;
      }
      button {
        align-items: center;
        background-color: var(--primary__color);
        border: none;
        cursor: pointer;
        display: flex;
        height: 3rem;
        justify-content: center;
        position: relative;
        width: 3rem;
        @include desktop {
          display: none;
        }
        &::before {
          content: '';
          position: absolute;
          width: 1.5rem;
          height: .2rem;
          background-color: var(--text_light__color);
          transform: translateY(-.5rem);
          box-shadow: 0 .5rem var(--text_light__color);
          transition: .5s;
        }
        &::after {
          content: '';
          position: absolute;
          width: 1.5rem;
          height: .2rem;
          background-color: var(--text_light__color);
          transform: translateY(.5rem);
          transition: .5s;
        }
        &.active::before {
          transform: translateY(0px) rotate(45deg);
          box-shadow: 0 0 var(--text_light__color);
        }
        &.active::after {
          transform: translateY(0px) rotate(-45deg);
        }
      }
    }
  }
}
