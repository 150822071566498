.overlapping__menu {
  display: none;
  gap: 4rem;
  justify-content: center;
  padding: 0 0 4rem 0;
  @include desktop {
    display: flex;
  }
  .overlapping__card {
    align-items: center;
    background-color: var(--background_light__color);
    border-radius: .5rem;
    box-shadow: 0 1rem 2rem var(--text_subtitle__color);
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: -10rem;
    padding: 2rem;
    transition: all 0.5s;
    width: 24rem;
    z-index: 10;
    &:hover {
      box-shadow: 0 1rem 2rem var(--dark__color);
      transform: scale(1.025);
    }
    svg {
      color: var(--secondary__color);
      font-size: 4rem;
    }
    h2 {
      color: var(--secondary__color);
      font-size: 2rem;
      font-weight: 600;
    }
    a {
      background-color: var(--secondary__color);
      color: var(--text_light__color);
      font-size: 1rem;
      font-weight: bold;
      padding: 1rem;
      text-align: center;
      text-decoration: none;
      width: 80%;
      &:hover {
        background-color: var(--primary__color);
      }
    }
  }
}

.brands__container {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 2rem;
}

.about__container {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  margin: 0 auto;
  padding: 2rem;
  text-align: center;
  @include desktop {
    width: 40%;
  }
  h2 {
    color: var(--secondary__color);
    font-size: 4rem;
  }
  p {
    color: var(--text__color);
    font-size: 1.125rem;
  }
}

.features__container {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  justify-content: center;
  padding: 2rem;
  @include desktop {
    flex-direction: row;
  }
  .features__card {
    align-items: center;
    background-color: var(--background_light__color);
    border-radius: .5rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    min-height: 16rem;
    justify-content: center;
    padding: 2rem;
    text-align: center;
    width: 80%;
    &:hover {
      box-shadow: 0 .25rem .5rem var(--text_subtitle__color);
      transition: all 1s;
    }
    @include desktop {
      flex-basis: 30%;
    }
    @include larger {
      flex-basis: 25%;
    }
    .feature__badge {
      align-items: center;
      background-color: var(--primary__color);
      border-radius: 50%;
      display: flex;
      height: 3rem;
      justify-content: center;
      width: 3rem;
      svg {
        color: var(--text_light__color);
        font-size: 1.5rem;
      }
    }
    h3 {
      color: var(--primary__color);
      font-size: 1.5rem;
    }
    p {
      color: var(--text__color);
      font-size: 1.125rem;
    }
  }
}

.banner__container {
  align-items: center;
  background: none;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  margin: 0 auto;
  overflow: hidden;
  padding: 7rem 2rem;
  position: relative;
  text-align: center;
  width: 100%;
  @include desktop {
    width: 100%;
  }
  #banner__background {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
  }
  h2 {
    color: var(--text_light__color);
    font-size: 2.5rem;
    z-index: 1;
  }
  p {
    color: var(--text_light__color);
    font-size: 1.5rem;
    margin-bottom: 2.75rem;
    z-index: 1;
  }
  small {
    color: var(--text_light__color);
    font-size: .875rem;
    z-index: 1;
  }
  .social__container {
    display: flex;
    gap: 1rem;
    z-index: 1;
    .social__button {
      align-items: center;
      background-color: var(--alpha_blue__color);
      border: 1px solid var(--background_light__color);
      color: var(--text_light__color);
      display: flex;
      gap: .5rem;
      justify-content: center;
      padding: 1rem;
      width: 9rem;
      font-weight: bold;
      transition: all .5s;
      &:hover {
        box-shadow: 0 .25rem .5rem var(--dark__color);
        transform: scale(1.05);
      }
    }
  }
}

.map__container {
  height: 70vh;
  position: relative;
  .map__zones {
    background: var(--background_light__color);
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 2rem;
    position: absolute;
    top: .5rem;
    left:.5rem;
    h3 {
      color: var(--secondary__color);
    }
    .zones__location {
      cursor: pointer;
      h4 {
        color: var(--primary__color);
        &.active {
          font-weight: bold;
        }
      }
      ul {
        li {
          list-style: none;
        }
      }
    }
  }
}

.warranty__container {
  align-items: center;
  display: flex;
  gap: 1rem;
  padding: 2.5rem 0;
  span {
    color: var(--primary__color);
    text-transform: uppercase;
  }
  div.warranty__bar {
    background: rgb(25,43,194);
    background: linear-gradient(90deg, var(--primary__color) 0%, rgba(255,255,255,0) 100%);
    flex-grow: 1;
    height: 1px;
  }
}
.search__form {
  align-items: center;
  background: var(--background_light__color);
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  padding: 1rem;
  div {
    display: flex;
    flex-grow: 1;
    gap: 1rem;
    input {
      border: none;
      color: var(--text__color);
      font-size: 1rem;
      flex-grow: 1;
      width: 100px;
      &:focus {
        outline: none;
      }
    }
    svg {
      color: var(--text__color);
    }
  }
  button {
    background-color: var(--primary__color);
    border: none;
    color: var(--text_light__color);
    padding: 1rem;
    @include tablet {
      padding: 1rem 2rem;
    }
  }
}
.pools__container {
  display: grid;
  gap: 1.5rem;
  grid-template-columns: 1fr;
  padding: 1.5rem 0;
  @include tablet() {
    grid-template-columns: repeat(2, 1fr);
  }
  @include desktop() {
    grid-template-columns: repeat(3, 1fr);
  }
  .pool__card {
    background-color: var(--background_light__color);
    display: flex;
    flex-direction: column;
    gap: .5rem;
    justify-content: space-between;
    overflow: hidden;
    padding: 1rem;
    @include tablet {
      gap: 1rem;
      padding: 1.5rem;
    }
    img {
      height: 22rem;
      object-fit: contain;
    }
    small {
      color: var(--text__color);
      font-size: 1rem;
      font-weight: bold;
      @include tablet {
        font-size: 1.125rem;
      }
    }
    h3 {
      color: var(--dark__color);
      font-size: 1.125rem;
      font-weight: bold;
      @include tablet {
        font-size: 1.5rem;
      }
    }
    .button {
      align-self: flex-start;
      background-color: var(--secondary__color);
      border: none;
      color: var(--text_light__color);
      text-decoration: none;
      padding: 1rem 2rem;
      &:hover {
        background-color: var(--primary__color);
      }
    }
  }
}

.complements__container {
  h3 {
    color: var(--dark__color);
    font-size: 2.5rem;
    font-weight: bold;
  }
}

.complements__sub__container {
  display: grid;
  gap: 1.5rem;
  grid-template-columns: 1fr;
  padding: 1.5rem 0;
  @include tablet() {
    grid-template-columns: repeat(2, 1fr);
  }
  @include desktop() {
    grid-template-columns: repeat(3, 1fr);
  }
  .complement__card {
    background-color: var(--background_light__color);
    display: flex;
    flex-direction: column;
    gap: .5rem;
    justify-content: space-between;
    overflow: hidden;
    padding: 1rem;
    @include tablet {
      gap: 1rem;
      padding: 1.5rem;
    }
    img {
      height: 22rem;
      object-fit: contain;
    }
    small {
      color: var(--text__color);
      font-size: 1rem;
      font-weight: bold;
      @include tablet {
        font-size: 1.125rem;
      }
    }
    h3 {
      color: var(--dark__color);
      font-size: 1.125rem;
      font-weight: bold;
      @include tablet {
        font-size: 1.5rem;
      }
    }
    .button {
      align-self: flex-start;
      background-color: var(--secondary__color);
      border: none;
      color: var(--text_light__color);
      text-decoration: none;
      padding: 1rem 2rem;
      &:hover {
        background-color: var(--primary__color);
      }
    }
  }
}

.complement__detail {
  .detail__title {
    align-items: baseline;
    display: flex;
    flex-direction: column;
    gap: .5rem;
    padding: 4rem 0 2rem 0;
    @include tablet {
      flex-direction: row;
    }
  }
  .detail__container {
    display: flex;
    flex-direction: column;
    gap: .5rem;
    .detail__gallery {
      img {
        object-fit: contain;
        width: 100%;
      }
    }
    .detail__content {
      display: flex;
      flex-direction: column;
      flex-basis: 50%;
      gap: 1rem;
      justify-content: center;
      padding: 1rem 0;
      p {
        color: var(--text__color);
        padding: 1rem 0;
      }
      .detail__sizes {
        p {
          color: var(--dark__color);
          padding: 0;
        }
      }
    }
    @include tablet {
      flex-direction: row;
      .detail__gallery {
        flex-basis: 45%;
      }
      .detail__content {
        flex-basis: 55%;

      }
    }
    @include desktop {
      .detail__gallery {
        flex-basis: 50%;
      }
      .detail__content {
        flex-basis: 50%;
        padding: 5%;
      }
    }
  }
  h3 {
    color: var(--dark__color);
    font-size: 1.75rem;
    font-weight: bold;
  }
  small {
    color: var(--text__color);
    font-size: 1rem;
    font-weight: bold;
  }
  @include tablet {
    h3 {
      font-size: 2.5rem;
    }
    small {
      font-size: 1.125rem;
    }
  }
}

.filter__toggle {
  & input[type='checkbox'] {
    opacity: 0;
    width: 0;
    height: 0;
  }
  svg {
    color: var(--text__color);
    cursor: pointer;
  }
  input:checked + input[type='checkbox'] {
    color: var(--text_subtitle);
  }
  input:checked + input[type='checkbox']:before {
    color: var(--text__color);
  }
}

.pool__detail {
  .detail__complements {
    padding-top: 2rem;
  }
  .detail__title {
    align-items: baseline;
    display: flex;
    flex-direction: column;
    gap: .5rem;
    padding: 4rem 0 2rem 0;
    @include tablet {
      flex-direction: row;
    }
  }
  .detail__container {
    display: flex;
    flex-direction: column;
    gap: .5rem;
    .detail__gallery {
      img {
        object-fit: contain;
        width: 100%;
      }
    }
    .detail__content {
      display: flex;
      flex-direction: column;
      flex-basis: 50%;
      gap: 1rem;
      justify-content: center;
      padding: 1rem 0;
      p {
        color: var(--text__color);
        padding: 1rem 0;
      }
      .detail__sizes {
        p {
          color: var(--dark__color);
          padding: 0;
        }
      }
    }
    @include tablet {
      flex-direction: row;
      .detail__gallery {
        flex-basis: 45%;
      }
      .detail__content {
        flex-basis: 55%;

      }
    }
    @include desktop {
      .detail__gallery {
        flex-basis: 50%;
      }
      .detail__content {
        flex-basis: 50%;
        padding: 5%;
      }
    }
  }
  h3 {
    color: var(--dark__color);
    font-size: 1.75rem;
    font-weight: bold;
  }
  small {
    color: var(--text__color);
    font-size: 1rem;
    font-weight: bold;
  }
  @include tablet {
    h3 {
      font-size: 2.5rem;
    }
    small {
      font-size: 1.125rem;
    }
  }
}

.whatsapp__widget {
  align-items: center;
  background-color: var(--whatsapp__color);
  border-radius: 50%;
  bottom: 1rem;
  display: flex;
  height: 3rem;
  justify-content: center;
  margin-left: auto;
  margin-top: -3rem;
  position: sticky;
  right: 2rem;
  transition: all .2s;
  width: 3rem;
  z-index: 1000;
  &:hover {
    box-shadow: 1px 1px 4px var(--dark__color);
  }
  a {
    align-items: center;
    display: flex;
    color: var(--text_light__color);
    font-size: 2rem;
    justify-content: center;
  }
}

.step__slider {
    width: 80%;
    margin: 3rem auto;
    .step__content {
        display: flex;
        flex-basis: 50%;
        flex-direction: column;
        @include desktop {
            flex-direction: row;
        }
    }
}

.step__control {
  display: flex;
  justify-content: space-between;
  position: relative;
  padding: 3rem 0;
  width: 80%;
  margin: 0 auto;
  .control__bar_disabled {
      background-color: var(--text_subtitle__color);
      border-radius: .25rem;
      height: .25rem;
      margin: auto 10px;
      left: 0;
      top: 0;
      bottom: 0;
      position: absolute;
      width: calc(100% - 1.25rem);
  }
  .control__bar {
    background-color: var(--secondary__color);
    border-radius: .25rem;
    height: .4rem;
    margin: auto 10px;
    left: 0;
    top: 0;
    bottom: 0;
    position: absolute;
    width: 0;
    transition: 1s all;
  }
  .control__button {
    align-items: center;
    background-color: rgba(50,50,255,.5);
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    height: 2.5rem;
    width: 2.5rem;
    z-index: 1;
    & div {
      align-items: center;
      background-color: var(--secondary__color);
      border-radius: 50%;
      display: flex;
      height: 1.25rem;
      justify-content: center;
      width: 1.25rem;
      z-index: 2;
      & div {
        background-color: var(--background_light__color);
        border-radius: 50%;
        height: .5rem;
        width: .5rem;
        z-index: 3;
      }
    }
  }
}

.step__images {
  overflow: hidden;
  position: relative;
  width: 100%;
  margin: 0 auto;
  @include desktop {
      width: 50%;
  }
  img {
    border: .25rem solid var(--primary__color);
    border-radius: .75rem;
    position: absolute;
    width: calc(100% - 50px);
    animation: fadeIn 1s ease-in-out;
  }
  #step__placeholder {
      width: 100%;
      position: relative;
      border: none;
      padding: 25px;
  }
}

.step__descriptions {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 2rem;
    @include desktop {
        width: 50%;
    }
    .step__description {
        display: flex;
        flex-direction: column;
        text-align: left;
        animation: fadeIn 1s ease-in-out;
        h3 {
            font-size: 1.5rem;
            color: var(--dark__color);
            @include desktop {
                font-size: 2.5rem;
            }
        }
        p {
            font-size: 1rem;
            color: var(--text_subtitle__color);
            @include desktop {
                font-size: 1.125rem;
            }
        }
        .description__button {
            background-color: var(--primary__color);
            border: none;
            color: var(--text_light__color);
            padding: 1rem;
            text-decoration: none;
            @include tablet {
                padding: 1rem 2rem;
            }
        }
    }
}

.fiber__container {
  h3 {
    color: var(--dark__color);
    font-size: 2.5rem;
    font-weight: bold;
  }
}

.coverflow__container__1P-xE {
  background: none !important;
}

.coverflow__figure__3bk_C {
  box-shadow: none !important;
}

.btn {
  background-color: var(--primary__color);
  border: none;
  color: var(--text_light__color);
  padding: 1rem;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    background-color: var(--secondary__color);
  }
  @include tablet {
    padding: 1rem 2rem;
  }
}

.btn-budget {
    align-items: center;
    align-self: flex-start;
    background-color: var(--whatsapp_dark__color);
    color: var(--text_light__color);
    display: flex;
    font-size: 1rem;
    font-weight: bold;
    gap: .5rem;
    padding: 1rem 2rem;
    text-align: center;
    text-decoration: none;
    &:hover {
      background-color: var(--whatsapp__color);
    }
    & svg {
      font-size: 1.5rem;
    }
}
